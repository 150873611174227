import React from "react";
import { useTranslation } from "react-i18next";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function Widget({
  header,
  count,
  percentage,
  completedTasks,
  totalTasks,
  delay,
  mainActionCallback,
}) {
  const { t } = useTranslation();
  return (
    <li
      className={classNames(
        "overflow-hidden rounded-xl border border-gray-200  shadow-lg",
        Number.parseFloat(percentage) === 100.0 ? "opacity-40" : "",
      )}
    >
      <div
        className={classNames(
          "flex items-center gap-x-4 border-b border-gray-900/5  px-6 py-3",
          " bg-white",
        )}
      >
        <div
          className={classNames(
            "text-base font-medium leading-6",
            "text-gray-900",
          )}
        >
          {header}
        </div>
        <div
          className={classNames(
            "ml-auto mt-1 rounded-xl px-4 py-1.5 text-right text-xl font-semibold tracking-tight",
            "bg-slate-200 text-gray-600",
          )}
        >
          {count}
        </div>
      </div>

      <dl className=" divide-y divide-gray-200 bg-slate-50 px-6 py-4 text-sm leading-6">
        <div className="flex justify-between gap-x-4  py-3">
          <dt className="text-gray-500">
            <span className="mr-6">
              {t("dashboard.efficiency.widgets.label.completedTasks")}
            </span>
            <span
              className={classNames(
                "inline-flex items-center gap-x-1.5 rounded-md px-2  py-1 text-xs font-medium",
                "bg-gray-400 text-white",
              )}
            >
              {percentage}%
            </span>
          </dt>
          <dd className="font-semibold tracking-wide text-gray-900">
            {completedTasks}/{totalTasks}
          </dd>
        </div>
        <div className="flex justify-between gap-x-4 py-3">
          <dt className="text-gray-500">
            {t("dashboard.efficiency.widgets.label.daysBeforeDeadline")}
          </dt>
          <dd className="flex items-start gap-x-2">
            <div className="font-semibold text-gray-900">
              {delay
                ? t("shared.component.time.day", {
                    count: delay,
                  })
                : "-"}
            </div>
          </dd>
        </div>
        {mainActionCallback && (
          <div className="flex justify-between gap-x-4 py-3">
            <div className="text-blue-400">
              {Number.parseFloat(percentage) !== 100.0 && (
                <a
                  className={classNames(
                    " cursor-pointer  text-sm text-blue-500 hover:underline",
                  )}
                  aria-current="page"
                  onClick={mainActionCallback}
                >
                  <span className="w-4 break-words">
                    {t("dashboard.efficiency.widgets.mainAction")}
                  </span>
                  <ChevronRightIcon
                    className={classNames("-mt-[0.1rem] inline-block h-5 w-5")}
                  />
                </a>
              )}
            </div>
          </div>
        )}
      </dl>
    </li>
  );
}
