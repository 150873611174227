import { createFetchAgentMachine } from "../../fetch-agent/fetch-agent-machine";

const { actions, sendParent, spawn } = require("xstate");
const { assign } = actions;

const spawnFetchAgent = assign({
  reportsAdvancedSearchOptionsAgentRef: (context, event) => {
    return spawn(
      createFetchAgentMachine({
        token: context?.authentication?.token,
        url: context.qmsToolApiUrl + "reports/advanced-search-options",
        loadedEventType: "AGENT.ADVANCED_SEARCH_DATA_LOADED",
        errorEventType: "AGENT.ADVANCED_SEARCH_LOADING_ERROR",
      }),
    );
  },
});

const saveAdvancedSearchData = assign({
  advancedSearchOptions: (context, event) => {
    return event.data;
  },
});

const setAdvancedSearchDataLoaded = assign({
  advancedSearchOptionsLoaded: (context, event) => {
    return true;
  },
});

const unsetDataLoaded = assign({
  advancedSearchOptionsLoaded: (context, event) => {
    return false;
  },
});

const sendLoadRequestToFetchAgent = (context, event) => {
  context.reportsAdvancedSearchOptionsAgentRef.send({
    type: "LOAD",
    data: {
      token: context?.authentication?.token,
      url:
        context.qmsToolApiUrl +
        "reports/advanced-search-options",
    },
  });
};

const sendUpdateBearerTokenEventTypeToFetchAgent = (context, event) => {
  context.reportsAdvancedSearchOptionsAgentRef.send({
    type: "UPDATE_BEARER_TOKEN",
    data: event.data,
  });
}

const sendErrorEventTypeToParent = sendParent((context) => ({
  type: context.errorEventType,
  data: context.error
}));

const sendLoadedEventTypeToParent = sendParent((context) => ({
  type: context.loadedEventType,
}));

const saveMonth = assign({
  month: (context, event) => {
    return event.data;
  },
});

const saveYear = assign({
  year: (context, event) => {
    return event.data;
  },
});

const setPeriodOptionsAsActiveTab = assign({
  activeTab: "period",
});

const setRangeOptionsAsActiveTab = assign({
  activeTab: "range",
});

const saveStartDate = assign({
  startDate: (context, event) => {
    return event.data;
  },
  endDate: (context, event) => {
    const result = context?.endDate;
    const startDate = new Date(event.data);
    if (result && startDate > new Date(result)) {
      return "";
    } else {
      return result;
    }
  },
});

const saveEndDate = assign({
  endDate: (context, event) => {
    console.log("sss");
    return event.data;
  },
});

const resetPeriodOptions = assign({
  month: (c, e) => null,
  year: (c, e) => null,
});

const resetRangeOptions = assign({
  startDate: (c, e) => null,
  endDate: (c, e) => null,
});

const sendClearedToParent = sendParent((context) => ({
  type: context.clearedEventType,
}));

const sendApplyEventToParent = sendParent((context) => ({
  type: context.applyEventType,
  data: {
    month: context.month,
    year: context.year,
    startDate: context.startDate,
    endDate: context.endDate,
  },
}));

const { flagChanges, unflagChanges, saveError, updateLastRefreshedTimestamp } = require("../../commons/actions");

export {
  spawnFetchAgent,
  saveAdvancedSearchData,
  setAdvancedSearchDataLoaded,
  unsetDataLoaded,
  sendLoadRequestToFetchAgent,
  sendUpdateBearerTokenEventTypeToFetchAgent, 
  sendErrorEventTypeToParent,
  sendLoadedEventTypeToParent,
  saveMonth,
  saveYear,
  resetPeriodOptions,
  resetRangeOptions,
  setPeriodOptionsAsActiveTab,
  setRangeOptionsAsActiveTab,
  saveStartDate,
  saveEndDate,
  flagChanges,
  unflagChanges,
  sendClearedToParent,
  sendApplyEventToParent,
};
