import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function BadRequest({ status = 400 }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className=" flex h-screen">
      <div className="m-auto">
        <div className="text-center">
          <p className="text-base font-semibold text-red-600">{status}</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-700 sm:text-5xl">
            {t("shared.components.badRequestError.title")}
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            {t("shared.components.badRequestError.description")}
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href="#"
              onClick={(event) => {
                event.preventDefault();
                navigate("/");
              }}
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {t("shared.components.badRequestError.action.go-back-home.label")}
            </a>
            <span
              className="cursor-not-allowed text-sm font-semibold text-gray-400"
              disabled={true}
            >
              {t(
                "shared.components.badRequestError.action.contact-support.label",
              )}{" "}
              <span aria-hidden="true">&rarr;</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
