import {
  UserCircleIcon,
  BuildingOffice2Icon,
  CalendarDaysIcon,
  Bars4Icon,
} from "@heroicons/react/24/outline";
import { FlagIcon as FlagIconOutline } from "@heroicons/react/24/outline";
import { FlagIcon } from "@heroicons/react/20/solid";
import { Trans, useTranslation } from "react-i18next";
import { useAuth } from "../../../context/AuthContext";
import DateTimeFormatter from "../DateTimeFormatter";
import GenericBadge from "../shared/GenericBadge";
import EventTypeBadge from "../EventTypeBadge";
import CriticityBadge from "../CriticityBadge";
import PeriodOfTheDayBadge from "../PeriodOfTheDayBadge";
import TimeAgo from "../shared/TimeAgo";
import { useViewEditReportMachine } from "../../../context/ViewEditReportMachineContext";
import { useCreateReportMachine } from "../../../context/CreateReportMachineContext";
import { useFlagSectionForReviewMachine } from "../../../context/FlagSectionForReviewMachineContext";
import { useRequalifyReportMachine } from "../../../context/RequalifyReportMachineContext";
import GenericSelectMenu from "../GenericSelectMenu";
import { TextArea } from "../TextArea";
import DateTimePicker from "../DateTimePicker";
import DropdownMenu from "../shared/DropdownMenu";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ReportView({ report }) {
  const { t } = useTranslation();
  const { keycloak } = useAuth();

  const [state, send] = useViewEditReportMachine();
  const [createReportState, createReportSend] = useCreateReportMachine();
  const [flagSectionForReviewState, flagSectionForReviewSend] =
    useFlagSectionForReviewMachine();
  const [requalifyReportState, requalifyReportSend] =
    useRequalifyReportMachine();

  const items = [
    {
      label: t("editReport.view.action.edit"),
      callback: () => {
        if (state.can("EDIT_REPORT")) {
          send("EDIT_REPORT");
        }
      },
      disabled: () => {
        return !state.can("EDIT_REPORT");
      },
      visible: () => {
        return state.can("EDIT_REPORT");
      },
    },
    {
      label: t("editReport.view.action.submit"),
      callback: () => {
        if (state.can("SUBMIT_REPORT")) {
          send("SUBMIT_REPORT");
        }
      },
      disabled: () => {
        return !state.can("SUBMIT_REPORT");
      },
      visible: () => {
        return state?.context?.report?.user_actions?.some(
          (action) => action.action_id === "submit",
        );
      },
    },
    {
      label: t("editReport.view.action.validate"),
      callback: () => {
        if (state.can("VALIDATE_REPORT")) {
          send("VALIDATE_REPORT");
        }
      },
      disabled: () => {
        return !state.can("VALIDATE_REPORT");
      },
      visible: () => {
        return (
          state?.context?.report?.user_actions?.some(
            (action) => action.action_id === "validate",
          ) && state.can("VALIDATE_REPORT")
        );
      },
    },
    {
      label: t("editReport.view.action.invalidate"),
      callback: () => {
        if (state.can("INVALIDATE_REPORT")) {
          send("INVALIDATE_REPORT");
        }
      },
      disabled: () => {
        return !state.can("INVALIDATE_REPORT");
      },
      visible: () => {
        return (
          state?.context?.report?.user_actions?.some(
            (action) => action.action_id === "invalidate",
          ) && state.can("INVALIDATE_REPORT")
        );
      },
    },
    {
      label: t("editReport.view.action.reopen"),
      callback: () => {
        if (state.can("REOPEN_REPORT")) {
          send("REOPEN_REPORT");
        }
      },
      disabled: () => {
        return !state.can("REOPEN_REPORT");
      },
      visible: () => {
        return (
          state?.context?.report?.user_actions?.some(
            (action) => action.action_id === "reopen",
          ) && state.can("REOPEN_REPORT")
        );
      },
    },

    {
      label: t("editReport.view.action.mark-as-sent"),
      callback: () => {
        if (state.can("MARK_REPORT_AS_SENT")) {
          send("MARK_REPORT_AS_SENT");
        }
      },
      disabled: () => {
        return !state.can("MARK_REPORT_AS_SENT");
      },
      visible: () => {
        return (
          state?.context?.report?.user_actions?.some(
            (action) => action.action_id === "mark-as-sent",
          ) && state.can("MARK_REPORT_AS_SENT")
        );
      },
    },
    {
      label: t("editReport.view.action.close"),
      callback: () => {
        if (state.can("CLOSE_REPORT")) {
          send("CLOSE_REPORT");
        }
      },
      disabled: () => {
        return !state.can("CLOSE_REPORT");
      },
      visible: () => {
        return (
          state?.context?.report?.user_actions?.some(
            (action) => action.action_id === "close",
          ) && state.can("CLOSE_REPORT")
        );
      },
    },

    {
      label: t("editReport.view.action.requalifyEvent"),
      callback: () => {
        requalifyReportSend("START", {
          authentication: {
            token: state?.context?.authentication?.token,
          },
          qmsToolApiUrl: state?.context?.qmsToolApiUrl,
          reportId: state?.context?.report?.id,
          changeSet: state?.context?.report.event,
          consequenceTypes: createReportState?.context?.consequenceTypes,
          severityTypes: createReportState?.context?.severityTypes,
          probabilityTypes: createReportState?.context?.probabilityTypes,
        });
      },
      disabled: () => {
        return false;
      },
      visible: () => {
        return state?.context?.report?.user_actions?.some(
          (action) => action.action_id === "requalify",
        );
      },
    },
  ];

  return (
    <>
      <div
        className={classNames(
          "overflow-hidden rounded-lg bg-white shadow  print:bg-none",
          state.matches("processing.initialized.active.edit") ? "" : "",
        )}
      >
        <div className="bg-white p-6  sm:px-6 sm:pb-7 sm:pt-6">
          <div
            className={classNames(
              "-mt-6 mb-5 w-full rounded-b-md  px-6  py-2 text-center text-sm capitalize text-zinc-500 shadow-inner  sm:-ml-6 sm:w-fit  sm:rounded-none sm:rounded-br-md sm:text-left",
              "bg-gray-300",
            )}
          >
            {t("shared.type.reportStatus." + report.status)}
          </div>
          <div className="sm:flex sm:items-center sm:justify-between">
            <div className="sm:flex sm:space-x-6">
              <div className="mb-6 mt-4 text-center sm:mb-0 sm:mt-0 sm:pt-1 sm:text-left">
                <p className="text-sm font-medium text-gray-600">
                  {t("editReport.view.title")}
                </p>
                <p className=" text-xl font-bold text-blue-600  sm:text-2xl">
                  {report.reference}
                </p>
              </div>
              <div className="mb-1 self-end text-center">
                <EventTypeBadge value={report.event.type}></EventTypeBadge>
              </div>
            </div>
            <div className="ml-3 mt-12 flex justify-center sm:mt-0">
              {Array.isArray(state?.context?.report?.user_actions) &&
                state?.context?.report?.user_actions.length > 0 &&
                state.matches("processing.initialized.active.view") && (
                  <div className="ml-3">
                    <DropdownMenu
                      label={t("editReport.view.actions.label")}
                      items={items.filter((item) => item.visible())}
                    />
                  </div>
                )}
              {state.can("CANCEL") && (
                <a
                  href="#"
                  className={classNames(
                    "ml-3 flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 hover:bg-gray-50",
                  )}
                  onClick={(e) => {
                    send("CANCEL");
                  }}
                >
                  {t("editReport.view.action.cancel")}
                </a>
              )}
              {state.matches("processing.initialized.active.edit") && (
                <button
                  href="#"
                  className="relative ml-3 inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 disabled:cursor-not-allowed disabled:bg-gray-400 "
                  onClick={(e) => {
                    send("SAVE_REPORT");
                  }}
                  disabled={state.context?.changeSet === undefined}
                >
                  {t("editReport.view.action.save")}
                </button>
              )}
            </div>
          </div>
          <div className="pt-6 sm:pt-5">
            <p className="text-center text-xs text-gray-400 sm:text-left">
              {t("editReport.view.label.updatedAt")} -{" "}
              <TimeAgo isoString={report?.event?.updated_at} />
            </p>
          </div>
        </div>
        <div>
          <div className="border-t border-gray-100 ">
            <dl className="bg-gray-50 ">
              {/* Consequence */}
              <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-600">
                  {t("editReport.view.sectionHeader.consequence")}
                </dt>
                <dd
                  className={classNames(
                    "mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0",
                  )}
                >
                  <GenericBadge
                    value={t(
                      "shared.type.consequence." +
                        report.event.consequence.name,
                    )}
                    obfuscated={report.obfuscated}
                  />
                </dd>
              </div>
              {/* Criticity */}
              <div className="px-4  py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-600">
                  {t("editReport.view.sectionHeader.criticity")}
                </dt>
                <dd
                  className={classNames(
                    "mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0",
                  )}
                >
                  <CriticityBadge
                    value={report.event.criticity.name}
                    obfuscated={report.obfuscated}
                  />
                </dd>
              </div>
              {/* Date and Time */}
              <div className=" border-t border-slate-200 px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-600">
                  {t("editReport.view.sectionHeader.eventDateTime")}
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                  <div className="flex flex-none gap-x-2 ">
                    <dt className="flex-none">
                      <CalendarDaysIcon
                        className="h-6 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </dt>
                    <dd
                      className={classNames("text-sm leading-6 text-gray-500")}
                    >
                      <time dateTime={report?.event?.event_occured_at}>
                        <DateTimeFormatter
                          isoString={report?.event?.event_occured_at}
                          obfuscated={report.obfuscated}
                        />
                      </time>
                    </dd>
                  </div>
                </dd>
              </div>

              {/* Period of the day */}
              <div className="  px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-600">
                  {t("editReport.view.sectionHeader.periodOfTheDay")}
                </dt>
                <dd
                  className={classNames(
                    "mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0",
                  )}
                >
                  {state.matches("processing.initialized.active.view") && (
                    <PeriodOfTheDayBadge
                      periodOfTheDay={report?.event?.period_of_the_day}
                      obfuscated={report.obfuscated}
                    />
                  )}
                  {state.matches("processing.initialized.active.edit") && (
                    <GenericSelectMenu
                      data={["DAY", "NIGHT"]}
                      selectedItem={report?.event?.period_of_the_day}
                      onItemSelected={(element) => {
                        console.log("element", element);
                        send("PERIOD_OF_THE_DAY_SELECTED", {
                          data: element,
                        });
                      }}
                      noSelectionText={t(
                        "createReport.steps.step3.form.placeholder.periodOfTheDay",
                      )}
                      useNameForKey={false}
                      useNameForValue={false}
                      i18nPrefixKey="shared.type.periodOfTheDay."
                    />
                  )}
                </dd>
              </div>
              {/* Declarant */}
              <div className=" px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-600">
                  {t("editReport.view.sectionHeader.submitter")}
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                  <div className="flex w-full flex-none gap-x-2  text-sm  leading-6 text-gray-900">
                    <dt className="flex-none">
                      <UserCircleIcon
                        className="h-6 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </dt>
                    <dd className="text-sm  leading-6 text-gray-500">
                      {report.submitter_username}
                    </dd>
                  </div>
                </dd>
              </div>
              {/* Residence */}
              <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-600">
                  {t("editReport.view.sectionHeader.residence")}
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                  <div className="flex w-full flex-none gap-x-2   text-sm leading-6 text-gray-900">
                    <dt>
                      <BuildingOffice2Icon
                        className="h-6 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </dt>
                    <dd className="text-sm  leading-6 text-gray-500">
                      {report?.residence_one_key}
                    </dd>
                  </div>
                </dd>
              </div>

              {/* Description */}
              <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-600">
                  {t("editReport.view.sectionHeader.description")}
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {state.matches("processing.initialized.active.view") && (
                    <>
                      <div className="flex w-full flex-none gap-x-2   text-sm leading-6 text-gray-900">
                        <dt>
                          <Bars4Icon
                            className="h-6 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </dt>
                        <dd
                          className={classNames(
                            "w-full text-sm leading-6 text-gray-500",
                            report.obfuscated ? "blur-sm" : "",
                          )}
                        >
                          {report.event.description}
                        </dd>
                      </div>
                    </>
                  )}
                  {state.matches("processing.initialized.active.edit") && (
                    <>
                      <TextArea
                        id="event-description"
                        name="event-description"
                        rows={10}
                        onChange={(event) => {
                          send("DESCRIPTION_CHANGED", {
                            data: event.target.value,
                          });
                        }}
                        value={report.event.description}
                      />
                    </>
                  )}
                </dd>
              </div>

              {/* Target */}
              <div className="border-t border-slate-200 px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-600">
                  {t("editReport.view.sectionHeader.target")}
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {state.matches("processing.initialized.active.view") && (
                    <>
                      <GenericBadge
                        value={t("shared.type.target." + report.event.target)}
                        obfuscated={report.obfuscated}
                      />
                    </>
                  )}
                  {state.matches("processing.initialized.active.edit") && (
                    <>
                      <GenericSelectMenu
                        data={createReportState.context?.targetTypes}
                        selectedItem={createReportState.context?.targetTypes.find(
                          (elt) => elt.name === report.event.target,
                        )}
                        onItemSelected={(element) => {
                          send("TARGET_SELECTED", { data: element.name });
                        }}
                        noSelectionText={t(
                          "createReport.steps.step4.form.placeholder.target",
                        )}
                        useNameForKey={true}
                        useEntireItemForValue={true}
                        i18nPrefixKey="shared.type.target."
                      />
                    </>
                  )}
                </dd>
              </div>
              {/* Category */}
              <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-600">
                  {t("editReport.view.sectionHeader.category")}
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {state.matches("processing.initialized.active.view") && (
                    <>
                      <GenericBadge
                        value={t(
                          "shared.type.category." + report.event.category,
                        )}
                        obfuscated={report.obfuscated}
                      />
                    </>
                  )}
                  {state.matches("processing.initialized.active.edit") && (
                    <>
                      <GenericSelectMenu
                        data={createReportState.context?.categories}
                        selectedItem={createReportState.context?.categories.find(
                          (elt) => elt.name === report.event.category,
                        )}
                        onItemSelected={(element) => {
                          send("CATEGORY_SELECTED", { data: element.name });
                        }}
                        noSelectionText={t(
                          "createReport.steps.step4.form.placeholder.category",
                        )}
                        useNameForKey={true}
                        useEntireItemForValue={true}
                        i18nPrefixKey="shared.type.category."
                      />
                    </>
                  )}
                </dd>
              </div>
              {/* Domain */}
              <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-600">
                  {t("editReport.view.sectionHeader.domain")}
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {state.matches("processing.initialized.active.view") && (
                    <>
                      <GenericBadge
                        value={t("shared.type.domain." + report.event.domain)}
                        obfuscated={report.obfuscated}
                      />
                    </>
                  )}
                  {state.matches("processing.initialized.active.edit") && (
                    <>
                      <GenericSelectMenu
                        //data={createReportState.context?.domains}
                        data={createReportState.context?.restrictedDomains}
                        //selectedItem={createReportState.context?.domains.find(
                        selectedItem={createReportState.context?.restrictedDomains.find(
                          (elt) => elt.name === report.event.domain,
                        )}
                        onItemSelected={(element) => {
                          send("DOMAIN_SELECTED", {
                            data: element.name,
                          });
                        }}
                        noSelectionText={t(
                          "createReport.steps.step4.form.placeholder.domain",
                        )}
                        useNameForKey={true}
                        useEntireItemForValue={true}
                        i18nPrefixKey="shared.type.domain."
                      />
                    </>
                  )}
                </dd>
              </div>
              {/* Location */}
              <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-600">
                  {t("editReport.view.sectionHeader.location")}
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {state.matches("processing.initialized.active.view") && (
                    <>
                      <GenericBadge
                        value={t(
                          "shared.type.location." + report.event.location,
                        )}
                        obfuscated={report.obfuscated}
                      />
                    </>
                  )}
                  {state.matches("processing.initialized.active.edit") && (
                    <>
                      <GenericSelectMenu
                        data={createReportState.context?.locations}
                        selectedItem={createReportState.context?.locations.find(
                          (elt) => elt.name === report.event.location,
                        )}
                        onItemSelected={(element) => {
                          send("LOCATION_SELECTED", {
                            data: element.name,
                          });
                        }}
                        noSelectionText={t(
                          "createReport.steps.step4.form.placeholder.location",
                        )}
                        useNameForKey={true}
                        useEntireItemForValue={true}
                        i18nPrefixKey="shared.type.location."
                      />
                    </>
                  )}
                </dd>
              </div>
              {/* Authority informed at */}
              <div className="border-t border-slate-200 px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-600">
                  {t("editReport.view.sectionHeader.authorityInformed")}
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  <div className="flex flex-none gap-x-2 ">
                    {(state.matches("processing.initialized.active.view") ||
                      (state.matches("processing.initialized.active.edit") &&
                        report.event.authority_informed_at)) && (
                      <>
                        <dt className="flex-none">
                          <CalendarDaysIcon
                            className="h-6 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </dt>
                        <dd className="text-sm leading-6 text-gray-500">
                          <time dateTime={report.event.authority_informed_at}>
                            <DateTimeFormatter
                              isoString={report.event.authority_informed_at}
                              obfuscated={report.obfuscated}
                            />
                          </time>
                        </dd>
                      </>
                    )}
                    {state.matches("processing.initialized.active.edit") &&
                      !report.event.authority_informed_at && (
                        <>
                          <DateTimePicker
                            initialDate={report.event.authority_informed_at}
                            onChange={(iso861DateStr) => {
                              send("AUTHORITY_INFORMED_DATE_SELECTED", {
                                data: iso861DateStr,
                              });
                            }}
                            min={report.event.event_occured_at}
                          />
                        </>
                      )}
                  </div>
                </dd>
              </div>
              {/* Family informed at */}
              <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-600">
                  {t("editReport.view.sectionHeader.familyInformed")}
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  <div className="flex flex-none gap-x-2 ">
                    {(state.matches("processing.initialized.active.view") ||
                      (state.matches("processing.initialized.active.edit") &&
                        report.event.family_informed_at)) && (
                      <>
                        <dt className="flex-none">
                          <CalendarDaysIcon
                            className="h-6 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </dt>
                        <dd className="text-sm leading-6 text-gray-500">
                          <time dateTime={report.event.family_informed_at}>
                            <DateTimeFormatter
                              isoString={report.event.family_informed_at}
                              obfuscated={report.obfuscated}
                            />
                          </time>
                        </dd>
                      </>
                    )}
                    {state.matches("processing.initialized.active.edit") &&
                      !report.event.family_informed_at && (
                        <>
                          <DateTimePicker
                            initialDate={report.event.family_informed_at}
                            onChange={(iso861DateStr) => {
                              send("FAMILY_INFORMED_DATE_SELECTED", {
                                data: iso861DateStr,
                              });
                            }}
                            min={report.event.event_occured_at}
                          />
                        </>
                      )}
                  </div>
                </dd>
              </div>
              {/* General Practitioner informed at */}
              <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-600">
                  {t(
                    "editReport.view.sectionHeader.generalPractitionerInformed",
                  )}
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  <div className="flex flex-none gap-x-2 ">
                    {(state.matches("processing.initialized.active.view") ||
                      (state.matches("processing.initialized.active.edit") &&
                        report.event.general_practitioner_informed_at)) && (
                      <>
                        <dt className="flex-none">
                          <CalendarDaysIcon
                            className="h-6 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </dt>
                        <dd className="text-sm leading-6 text-gray-500">
                          <time
                            dateTime={
                              report.event.general_practitioner_informed_at
                            }
                          >
                            <DateTimeFormatter
                              isoString={
                                report.event.general_practitioner_informed_at
                              }
                              obfuscated={report.obfuscated}
                            />
                          </time>
                        </dd>
                      </>
                    )}
                    {state.matches("processing.initialized.active.edit") &&
                      !report.event.general_practitioner_informed_at && (
                        <>
                          <DateTimePicker
                            initialDate={
                              report.event.general_practitioner_informed_at
                            }
                            onChange={(iso861DateStr) => {
                              send(
                                "GENERAL_PRACTITIONER_INFORMED_DATE_SELECTED",
                                {
                                  data: iso861DateStr,
                                },
                              );
                            }}
                            min={report.event.event_occured_at}
                          />
                        </>
                      )}
                  </div>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        {state?.context?.report?.user_actions?.some(
          (action) => action.action_id === "event-request-review",
        ) &&
          !state.context?.report?.event?.review_requested_at && (
            <div className="border-t border-gray-200 bg-slate-50  px-4 pb-3 pt-4 text-sm font-medium text-gray-500 sm:px-6">
              <div className="flex items-center justify-between">
                <div>
                  {t("editReport.view.immediateActions.label.requestReview")}
                </div>
                <div>
                  <button
                    className=" flex items-center justify-center rounded-md   px-2.5 py-2 text-sm font-medium text-gray-600 shadow-sm ring-1 ring-inset ring-gray-400 hover:bg-amber-300 hover:text-gray-700 hover:ring-amber-600 disabled:cursor-not-allowed disabled:bg-gray-400 disabled:text-white disabled:ring-0"
                    onClick={(e) => {
                      flagSectionForReviewSend("START", {
                        data: {
                          action_url:
                            state?.context?.report?.user_actions?.find(
                              (action) =>
                                action.action_id === "event-request-review",
                            )?.url,
                          authentication: {
                            token: state?.context?.authentication?.token,
                          },
                        },
                      });
                    }}
                  >
                    <FlagIcon className="h6 w-5"></FlagIcon>
                  </button>
                </div>
              </div>
            </div>
          )}
        {state.context?.report?.event?.review_requested_at && (
          <div className=" bg-amber-300 p-6  text-sm  text-gray-600 sm:px-6 sm:pb-5 sm:pt-6">
            <div className="flex justify-between">
              <div className="mb-6 flex">
                <FlagIconOutline className="h6 mr-3 w-5"></FlagIconOutline>
                <div>
                  {" "}
                  <Trans
                    i18nKey={`editReport.view.activity.REPORT_REVIEW_REQUESTED`}
                    components={{
                      span: <span className="font-medium text-gray-700" />,
                    }}
                    values={{
                      username:
                        state.context?.report?.event?.review_requester_username,
                    }}
                  />
                </div>
              </div>
              <div className="">
                <TimeAgo
                  isoString={state.context?.report?.event?.review_requested_at}
                />
              </div>
            </div>
            <div>“{state.context?.report?.event?.review_description}”</div>
          </div>
        )}
      </div>
    </>
  );
}
