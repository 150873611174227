import DateTimePicker from "../DateTimePicker";
import { useTranslation } from "react-i18next";
import { CalendarDateRangeIcon } from "@heroicons/react/24/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function getLastReportingWeek() {
  const now = new Date();

  // Find the most recent Thursday
  const today = now.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
  const daysToLastThursday = (today + 3) % 7; // Days back to Thursday (4)

  const lastThursday = new Date(now);
  lastThursday.setDate(now.getDate() - daysToLastThursday);
  lastThursday.setHours(0, 0, 0, 0); // Set time to 00:00:00:000

  // Calculate start of the reporting week (Thursday of the previous week)
  const startDate = new Date(lastThursday);
  startDate.setDate(lastThursday.getDate() - 7); // Go back 7 days to the previous Thursday

  // End date is the end of the last Thursday (23:59:59.999)
  const endDate = new Date(lastThursday);
  endDate.setHours(23, 59, 59, 999); // Set time to 23:59:59:999

  return {
    startDate: startDate,
    endDate: endDate,
  };
}

export default function RangeOptions({ advancedSearchAgentRef, forceUpdate }) {
  const { t } = useTranslation();
  const lastReportingWeek = getLastReportingWeek();
  return (
    <>
      <div className=" ">
        <DateTimePicker
          label={t("shared.searchWidget.options.range.startDate")}
          initialDate={advancedSearchAgentRef?.state?.context?.startDate}
          onChange={(iso861DateStr) => {
            advancedSearchAgentRef?.send({
              type: "START_DATE_SELECTED",
              data: iso861DateStr,
            });
            forceUpdate();
          }}
          min={advancedSearchAgentRef?.state?.context?.advancedSearchOptions?.range?.minDate}
        />
      </div>
      <div className="mb-2 mt-2 ">
        <DateTimePicker
          label={t("shared.searchWidget.options.range.endDate")}
          initialDate={advancedSearchAgentRef?.state?.context?.endDate}
          onChange={(iso861DateStr) => {
            advancedSearchAgentRef?.send({
              type: "END_DATE_SELECTED",
              data: iso861DateStr,
            });
            forceUpdate();
          }}
          max={advancedSearchAgentRef?.state?.context?.advancedSearchOptions?.range?.maxDate}
        />
      </div>
      <div className="mb-2 mt-6">
        <a
          href="#"
          className="text-blue-500 hover:text-blue-700"
          onClick={() => {
            advancedSearchAgentRef.send({
              type: "START_DATE_SELECTED",
              data: lastReportingWeek.startDate.toISOString(),
            });
            advancedSearchAgentRef.send({
              type: "END_DATE_SELECTED",
              data: lastReportingWeek.endDate.toISOString(),
            });
            forceUpdate();
          }}
        >
          <div className="flex items-center">
            <CalendarDateRangeIcon className={"mr-2 inline-flex h-5 w-5"} />
            <span>{t("shared.searchWidget.options.range.lastReporting")}</span>
          </div>
        </a>
      </div>
    </>
  );
}
