import { Trans, useTranslation } from "react-i18next";
import TimeAgo from "../shared/TimeAgo";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Activity({ data, obfuscated = false }) {
  const { t } = useTranslation();
  return (
    <>
      <h2 className="mb-6 text-2xl font-medium leading-6 text-gray-600 xl:mb-4 xl:text-lg ">
        {t("editReport.view.activity.title")}
      </h2>
      <ul role="list" className="space-y-6 px-4 pt-5">
        {data.map((activityItem, activityItemIdx) => (
          <li key={activityItem.id} className="relative flex gap-x-4">
            <div
              className={classNames(
                activityItemIdx === data.length - 1 ? "h-6" : "-bottom-6",
                "absolute left-0 top-0 flex w-6 justify-center",
              )}
            >
              <div className="w-px bg-gray-200" />
            </div>
            {activityItem.activity_type.startsWith("NOTIFICATION") ? (
              <>
                <div className="relative flex h-6 w-6 flex-none items-center justify-center ">
                  <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                </div>

                <div className="flex-auto ">
                  <div className="flex justify-between gap-x-4">
                    <div className="py-0.5 text-xs leading-5 text-gray-500">
                      <Trans
                        i18nKey={`editReport.view.activity.${activityItem.activity_type}`}
                        components={{
                          span: <span className="font-medium text-gray-900" />,
                        }}
                        values={{ destination: activityItem.destination }}
                      />
                    </div>
                    <time
                      dateTime={activityItem.created_at}
                      className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                    >
                      <TimeAgo isoString={activityItem.created_at} />
                    </time>
                  </div>
                  {activityItem.activity_type === "NOTIFICATION_SMS_SENT" ? (
                    <p
                      className={classNames(
                        "mt-2 rounded-md p-3 text-xs leading-6 text-gray-500 ring-1 ring-inset ring-gray-200",
                        obfuscated ? "blur-sm" : "",
                      )}
                    >
                      {activityItem.description}
                    </p>
                  ) : null}
                </div>
              </>
            ) : (
              <>
                <div className="relative flex h-6 w-6 flex-none items-center justify-center ">
                  <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                </div>
                <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                  <Trans
                    i18nKey={`editReport.view.activity.${activityItem.activity_type}`}
                    components={{
                      span: <span className="font-medium text-gray-900" />,
                    }}
                    values={{ username: activityItem.user_username }}
                  />
                </p>
                <time
                  dateTime={activityItem.created_at}
                  className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                >
                  <TimeAgo isoString={activityItem.created_at} />
                </time>
              </>
            )}
          </li>
        ))}
      </ul>
    </>
  );
}
