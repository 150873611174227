import { useListReportsMachine } from "../../../context/ListReportsMachineContext";
import YearListbox from "./YearListbox";
import MonthListbox from "./MonthListbox";

export default function PeriodOptions({ advancedSearchAgentRef, forceUpdate }) {
  const [state, send] = useListReportsMachine();

  return (
    <div className="flex justify-normal gap-x-2">
      <div className="flex ">
        <div className="">
          <YearListbox
            state={advancedSearchAgentRef?.state}
            send={advancedSearchAgentRef?.send}
          />
        </div>
      </div>
      <div className="flex ">
        <MonthListbox
          state={advancedSearchAgentRef?.state}
          send={advancedSearchAgentRef?.send}
        />
      </div>
    </div>
  );
}
