const { createMachine } = require("xstate");
const actions = require("./actions");
const guards = require("./guards");
const services = require("./services");

const createAdvancedSearchAgentMachine = ({
  qmsToolApiUrl,
  authentication,
  periodSelectedEventType,
  rangeSelectedEventType,
  clearedEventType,
  applyEventType,
  errorEventType,
}) => {
  return createMachine(
    {
      /** @xstate-layout N4IgpgJg5mDOIC5QEMIDdkDsDGkAEsYyATtgBYDEAwgDICiAggEoDaADALqKgAOA9rACWAF0F9M3EAA9EAdgBMbAHQBWeSoCcAZgCMWlWy1aAbDoA0IAJ6IAtPN1KALFrYaAHC5c6Dj4wF8-C1QMHHxCEnIKBgAFaJoATXYuJBB+IVFxSRkEeR0NJQ1ZFX09LQ1FNmNHC2sEHTZ1JQ8NY2NZDR1ZWT0VAKD0LFwIAiJSMiVBCAAbMAokyTSRMQkU7JtC1VkXdS0PR3lHDRrbFRU3AsLfN3sVfdM+kGDBsNHyJTBiYj5iCiY6ABUmIlOAsBEtMqtED0Cm4VN1HIdtEY3G5jggbGp5KoEVoEY49I5ujoHk9QsNwmMlFM+KhBJgoFEAOJ0ABy-yUDAAIgA1Bgsqh0TkAfQAyowmFQABJCmgAeS5AEkWYyhXQmExZawQSlFhkVqBsvI3MYlGwGpiDvJ5MZrbI0TYylircadG5fC42lsSQMySMIuNqbT6UzWeyubz+YLReKpULOQx-gwZfLOYL5jqwXqsrYylolJ0tAp1GcXeYrLZ7HnTq03IZZGxbiitN6QkM-ZTAxA6Qz07xM8tswgHN5dl0imPHA2VPbvLJTbnFCY8mxus3Ao8fW2KW9O5AKABVaLx-50IUAIXFaqF-1lAGlWb3Uv2IQbbMYUUojQcNNokXojuWdTGCoSiyB43g6DoxgdI4biyI4LbPOSrwBjSECQEo5BgNgADWeDINgohoGAeDCMgABGczan26QDpC6I6EoWjWma+JXG4LQIsYaK5IxnQInCGhmhoCJQYhvrbqhqAYVhuH4YRgjEaRFFzDoyQ0eC+rSIguyONibh5O0HRFLW3GAbx+bwY4gnCaJ-jrqSW4oVSaEYTwHxiBAFAALKymy0pivQVAnpyj66nRr7oooIEQSuy7yB0HHVIBKh6E0WxwrWK7OGB4lOf6LnSRASjucQnkUPE4rRkFIVhc+WlrDo9ifr4agmWwOi+HkaLviaRqHHBKjGLshR5S8BW7sVpXldyCp0AA6kKTB8syQqytE-wKn5Ip1bRL7aUBelGAWBn6HBNrJbURj5KYhRtOUiLaGNyETa5xXEFgMAULNC1CtEapbcK62bdtu2aYOnSMfYRllBoqUGGZtTFPkQ0wWaUGFvBz3tjub1KB99KzCKiZMP8cYJqegV0MFabUU+e0NYgZyMYUBxsPiXTqHBaImFimjDZUhiTqcbjY5JhXoe9n2zKywrHpTdA1bT6n0+D9GaMo3SVAZRYGK6aJDXz1ltDrhLtMSDyYHw6HwCkjnjWMoIM4ONhbH11yIrmuhlPa9YgSipjGK41x5E1YvOZMMxO2rkUOma842p1DR5LkWj2qY5y7IHujviiCjhwVHxfMQ0dZvRuImnBBwGAYD0dZdJytEow07M4+JCR0Bcdmh3alxFB0YnmVqaN4HhtKcPuATYk4mp1qc5SUxpd7jRV9-taw7PmqVxUJCWuiJBt8XBkG9doboosvUmS5hZDYXhBFESRZHkWvjM5HxXQdPotyaBxDRonoc474iRXGtCiFol8JZuQ8tbV+g44ROCEviSC7gugGQbGiay5wzj2CbIUNgosHKbgdiva+BMYBwPotZfI5RdA-lgmBJqDcEBYNUNcQs7MGxCU0AEAIQA */
      id: "advanced search",

      predictableActionArguments: true,

      context: {
        activeTab: "period",
        month: null,
        year: null,
        startDate: null,
        endDate: null,
        qmsToolApiUrl: qmsToolApiUrl,
        authentication: authentication,
        periodSelectedEventType: periodSelectedEventType,
        rangeSelectedEventType: rangeSelectedEventType,
        clearedEventType: clearedEventType,
        applyEventType: applyEventType,
        errorEventType: errorEventType,
      },

      states: {
        "idle": {
          always: {
            target: "loading",
            actions: [
              "spawnFetchAgent",
            ]
          },
        },

        error: {
          on: {
            RETRY: "loading",
          },
  
          entry: "saveError",
        },

        loading: {
          entry: ["sendLoadRequestToFetchAgent"],
  
          always: {
            target: "loaded",
            cond: "allDataLoaded",
            actions: [
              "unsetDataLoaded",
              "updateLastRefreshedTimestamp",
            ],
          },
  
          on: {
            "AGENT.ADVANCED_SEARCH_LOADING_ERROR": {
              target: "error",
              actions: ["unsetDataLoaded", "sendErrorEventTypeToParent"]
            },
  
            "AGENT.ADVANCED_SEARCH_DATA_LOADED": {
              target: "loading",
              internal: true,
              actions: ["saveAdvancedSearchData", "setAdvancedSearchDataLoaded"],
            },
          },
        },

        loaded: {
          initial: "check active tab",

          states: {
            "check active tab": {
              always: [
                {
                  target: "period",
                  cond: "periodOptionsActive",
                },
                {
                  target: "range",
                  cond: "rangeOptionsActive",
                },
              ],
            },

            period: { 
              on: {
                MONTH_SELECTED: {
                  target: "period",
                  internal: true,
                  actions: ["saveMonth", "resetRangeOptions", "flagChanges"],
                  cond: "validMonth",
                },

                YEAR_SELECTED: {
                  target: "period",
                  internal: true,
                  actions: ["saveYear", "resetRangeOptions", "flagChanges"],
                  cond: "validYear",
                },

                VIEW_RANGE_OPTIONS: {
                  target: "range",
                  actions: "setRangeOptionsAsActiveTab",
                },
              },
            },

            range: {
              on: {
                VIEW_PERIOD_OPTIONS: {
                  target: "period",
                  actions: "setPeriodOptionsAsActiveTab",
                },

                START_DATE_SELECTED: {
                  target: "range",
                  internal: true,
                  actions: ["saveStartDate", "resetPeriodOptions", "flagChanges"],
                },

                END_DATE_SELECTED: {
                  target: "range",
                  internal: true,
                  actions: ["saveEndDate", "resetPeriodOptions", "flagChanges"],
                },
              },
            }
          },

          on: {
            UPDATE_BEARER_TOKEN: {
              target: "loaded",
              internal: true,
              cond: "validToken",
              actions: "sendUpdateBearerTokenEventTypeToFetchAgent"
            }
          },
        }
      },

      initial: "idle",

      on: {
        CLEAR: {
          target: ".loaded.check active tab",
          actions: [
            "resetPeriodOptions",
            "resetRangeOptions",
            "unflagChanges",
            "sendClearedToParent",
          ],
          cond: "changesHappened",
        },

        APPLY: {
          target: "#advanced search",
          internal: true,
          cond: "validPeriodOrRangeSelected",
          actions: "sendApplyEventToParent",
        },
      },
    },
    { actions, guards, services },
  );
};

export { createAdvancedSearchAgentMachine };
