import axios from "axios";
const defaultGetOptions = {
  method: "GET",
  maxBodyLength: Infinity,
};

const fetchActionPlans = (context, event) => {
  const options = {
    ...defaultGetOptions,
    headers: { Authorization: `Bearer ${context.authentication.token}` },
  };
  return axios(`${context.qmsToolApiUrl}action-plans`, options).then(
    (response) => response.data,
  );
};

export { fetchActionPlans };
