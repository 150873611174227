

const allDataLoaded = (context, event) => {
  return (
    context?.advancedSearchOptionsLoaded === true
  );
};

const validMonth = (context, event) => {
  const result =
    event.data !== null &&
    Number.parseInt(event.data) > 0 &&
    Number.parseInt(event.data) < 13;

  return result;
};

const validYear = (context, event) => {
  const result = event.data !== null && Number.parseInt(event.data) > 0;

  return result;
};

const periodOptionsActive = (context, event) => {
  console.log("context.activeTab", context.activeTab);
  return context.activeTab === "period";
};

const rangeOptionsActive = (context, event) => {
  return context.activeTab === "range";
};

const validPeriodOrRangeSelected = (context, event) => {
  if (context.activeTab === "period") {
    return (context.year && !context.month) || (context.month && context.year);
  } else if (context.activeTab === "range") {
    return (
      context.startDate &&
      context.endDate &&
      new Date(context.startDate) < new Date(context.endDate)
    );
  }
};

const { validLoadRequest, changesHappened, validToken } = require("../../commons/guards");

export {
  allDataLoaded,
  periodOptionsActive,
  rangeOptionsActive,
  validMonth,
  validYear,
  validToken,
  changesHappened,
  validLoadRequest,
  validPeriodOrRangeSelected,
};
